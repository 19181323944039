import cn from 'classnames';
import Link from 'next/link';
import React from 'react';

const Button = ({
  children,
  href,
  isDisabled = false,
  asPath,
  onClick,
  ref,
  className,
  size = 'lg',
  variant = 'primary',
  hasArrow = false,
  openWindow = false,
  rel,
}: {
  asPath?: string;
  children: any;
  className?: string;
  hasArrow?: boolean;
  href?: string;
  isDisabled?: boolean;
  onClick?: any;
  openWindow?: boolean;
  ref?: any;
  rel?: string;
  size?: 'lg' | 'md' | 'sm';
  variant?:
    | 'primary'
    | 'ghost'
    | 'ghost-inverted'
    | 'text'
    | 'border'
    | 'black'
    | 'white'
    | 'primary-timescale';
}) => {
  const variants = {
    primary:
      'bg-primary text-white font-semibold shadow-sm hover:shadow-primary-button focus:shadow-cta',
    ghost:
      'bg-transparent text-black dark:text-white font-semibold hover:bg-gray-100 dark:hover:bg-cobalt-50',
    'ghost-inverted':
      'bg-transparent text-white font-semibold hover:bg-cobalt-200',
    black: 'bg-black text-white font-semibold hover:bg-black',
    white: 'bg-white text-black font-semibold hover:bg-white',
    border:
      'bg-white text-black dark:text-white border font-semibold shadow-sm hover:shadow-xl',
    text: 'bg-white text-black font-semibold hover:text-black/90',
    'primary-timescale':
      'bg-electricYellow text-black font-semibold hover:electricYellow-500',
  };

  const sizes = {
    sm: 'py-2 px-3 text-xs sm:text-sm',
    md: 'py-2 px-3 sm:py-3 sm:px-4 text-xs sm:text-sm',
    lg: 'py-3 px-5 sm:py-4 sm:px-6 text-sm sm:text-base',
  };

  const hoverArrowClasses =
    'transition shrink-0 ease-in-out group-hover:translate-x-[2px] ml-[4px]';

  const classes = cn(
    'flex text-center items-center justify-center group',
    'shrink-0 w-auto cursor-pointer font-inter transition ease-in-out leading-none',
    'focus:outline-none hover:opacity-95',
    'rounded-full',
    variants[variant],
    variant !== 'text' && sizes[size],
    className,
    {
      'opacity-50 pointer-events-none': isDisabled,
    },
  );
  return (
    <>
      {href ? (
        <Link ref={ref} href={href} as={asPath}>
          <a
            onClick={onClick}
            className={classes}
            target={openWindow ? '_blank' : undefined}
            rel={rel}
          >
            {children}
            {hasArrow && <div className={hoverArrowClasses}>{`->`}</div>}
          </a>
        </Link>
      ) : (
        <button
          disabled={isDisabled}
          ref={ref}
          className={classes}
          onClick={onClick}
        >
          {children}
          {hasArrow && <div className={hoverArrowClasses}>{`->`}</div>}
        </button>
      )}
    </>
  );
};

export default Button;
